<!--
 * @Description: 
 * @Author: lzp
 * @Date: 2021-09-08 17:46:45
 * @LastEditTime: 2021-10-14 11:29:23
 * @LastEditors: lzp
-->
<template>
  <div class="school">
    <Header />
    <div class="head_box">
      <div class="search_content">
        <div class="search_box">
          <el-input
            class="el_ipt"
            prefix-icon="el-icon-search"
            v-model="keyword"
            @keyup.enter.native="hSearch"
            :placeholder="placeholder"
            clearable
          ></el-input>
          <el-button class="el_btn" @click="hSearch">搜索</el-button>
        </div>
        <div class="search_link">
          <span v-for="(item, idx) in linkList" :key="item.SSGLRMTJ_DBT">
            <span
              class="search_link_txt"
              @click="; (keyword = item.SSGLRMTJ_DBT), hSearch()"
            >{{ item.SSGLRMTJ_DBT }}</span>
            <span class="search_link_line" v-show="idx < linkList.length - 1">|</span>
          </span>
        </div>
      </div>
      <div class="tabs">
        <span
          @click="showTab = 'material'"
          :class="{ tabs_item: true, tabs_item_active: showTab != 'material' }"
        >教学资料</span>
        <span
          @click="showTab = 'results'"
          :class="{ tabs_item: true, tabs_item_active: showTab != 'results' }"
        >搜索结果</span>
      </div>
    </div>
    <div class="content_box">
      <div class="study" v-if="showTab == 'material'">
        <div class="box_title">学习板块</div>
        <div class="study_content">
          <div
            class="study_item"
            :style="{ backgroundImage: 'url(' + item.bg + ')' }"
            v-for="item in studyList"
            :key="item.text"
            @click="hStudy(item)"
          ></div>
        </div>
      </div>
      <div class="results" v-if="showTab == 'results' && totalCount > 0" v-loading="loading">
        <p class="tip">已为您找到 {{ totalCount }} 个相关结果
          <span class="askQuestions" @click="askQuestions">找不到答案，我要去提问！</span>
        </p>
        <div class="res_item" v-for="item in searchResult" :key="item.id">
          <p
            class="res_item_title"
            @click="tjump(item)"
            v-html="
              item.hightlighter.title ? item.hightlighter.title : item.title
            "
          ></p>
          <div class="res_item_content">
            <div
              class="haspic"
              v-if="
                (!!item.picture && item.picture != 'null') ||
                item.uri == 'videoCourse'
              "
            >
              <!-- <div class="pic" :style="{backgroundImage: 'url('+item.picture +')'}"></div> -->
              <div class="pic">
                <img
                  v-if="item.uri == 'videoCourse'"
                  src="../../../../assets/imgs/school/video.png"
                />
                <img v-else :src="item.picture" />
              </div>

              <div class="haspic_content">
                <div v-if="item.uri == 'videoCourse'">
                  播放量：{{ item.playNum || 0 }}
                  <br />
                  分 &nbsp; 类：{{ item.videoType || '视频教学' }}
                </div>
                <div
                  v-else
                  class="haspic_txt txt_overflow"
                  v-html="
                    item.hightlighter.content
                      ? item.hightlighter.content
                      : item.content
                  "
                ></div>
                <div class="haspic_from">{{ item.source }}</div>
              </div>
            </div>
            <div class="nopic" v-else>
              <div
                class="nopic_txt txt_overflow"
                v-html="
                  item.hightlighter.content
                    ? item.hightlighter.content
                    : item.content
                "
              ></div>
              <div class="nopic_from" @click="jump(item.url)">{{ item.source }}</div>
            </div>
          </div>
        </div>

        <div class="pagination">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            layout="prev, pager, next"
            :total="totalCount"
            hide-on-single-page
          ></el-pagination>
        </div>
      </div>
      <div class="noresults" v-if="showTab == 'results' && totalCount === 0">
        抱歉没有找到与“
        <span class="keyword">{{ keyword_ }}</span>”相关的结果
        <span class="askQuestions" @click="askQuestions">找不到答案，我要去提问！</span>
      </div>
      <!-- <div class="about" v-if="showTab=='results'">
        <div class="box_title">相关搜索</div>
        <div class="about_content">
          <div class="about_item" v-for="item in aboutList" :key="item.text">{{item.text}}</div>
        </div>
      </div>-->
      <div class="hot">
        <div class="box_title">热门推荐</div>
        <div class="hot_content">
          <div class="hot_item" v-for="item in hotList" :key="item.text">
            <span class="dot">•</span>
            <span class="txt" @click="jump(item.SSGLRMTJ_TZLJ)">
              {{
                item.SSGLRMTJ_BT
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="question">
        <div class="box_title">有问必答</div>
        <div class="question_content">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="80px">
            <el-row :gutter="20">
              <el-col :span="11">
                <el-form-item label="姓名:" prop="name">
                  <el-input placeholder="请输入您的姓名" v-model="ruleForm.name"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="4">
                <el-button-group class="contact_btn_group">
                  <el-button
                    v-for="item in contactList"
                    :key="item.id"
                    :class="{
                      contact_btn: true,
                      btn_active: hContact == item.code
                    }"
                    @click="changeContact(item)"
                  >{{ item.text }}</el-button>
                </el-button-group>
              </el-col>
              <el-col :span="9">
                <el-form-item prop="contact" class="contact" ref="mycontact">
                  <el-input
                    class="contact_ipt"
                    :placeholder="hContact == 'PHONE' ? '+86' : ''"
                    v-model="ruleForm.contact"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="分类:" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio v-for="item in typeList" :key="item.code" :label="item.text"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="留言:" prop="desc">
              <el-input
                ref="inputRef"
                type="textarea"
                :rows="5"
                placeholder="请留下您关心的一切问题。"
                v-model="ruleForm.desc"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="subbtn" type="danger" @click="saveQus('ruleForm')">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import {
  searchQuery,
  searchHome,
  saveQus,
  getDic,
  addOnce
} from '../../actions/actions'
export default {
  name: 'schoolPage',
  data() {
    return {
      showTab: 'material',
      loading: false,

      keyword: '',
      keyword_: '',
      linkList: [],
      placeholder: '',
      studyList: [
        {
          text: '社区论坛',
          linkurl: 'http://bbs.jepaas.com',
          bg: require('../../../../assets/imgs/school/sq.jpg')
        },
        {
          text: '视频教程',
          linkurl: 'http://www.jepaas.com/videoCourse',
          bg: require('../../../../assets/imgs/school/sp.jpg')
        },
        {
          text: '帮助文档',
          linkurl: 'http://doc.jepaas.com/docs/je_doc_jepaas',
          bg: require('../../../../assets/imgs/school/bz.jpg')
        },
        {
          text: 'API手册',
          linkurl: 'http://doc.jepaas.com/docs/je-doc-api',
          bg: require('../../../../assets/imgs/school/api.jpg')
        }
      ],
      hotList: [],
      // aboutList: [
      //   { text: 'PaaS', linkurl: '' },
      //   { text: 'PaaS', linkurl: '' },
      //   { text: 'PaaS', linkurl: '' },
      //   { text: 'PaaS', linkurl: '' },
      //   { text: 'PaaS系统', linkurl: '' },
      //   { text: 'PaaS系统', linkurl: '' },
      //   { text: 'PaaS系统', linkurl: '' },
      //   { text: 'PaaS系统', linkurl: '' },
      //   { text: 'PaaS', linkurl: '' },
      //   { text: 'Pa搜索aS', linkurl: '' },
      //   { text: 'ddd', linkurl: '' },
      //   { text: 'PaaS', linkurl: '' }
      // ],
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        contact: [
          {
            validator: this.validatorContact,

            trigger: 'blur'
          }
        ],
        type: [{ required: true, message: '请选择分类', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入留言', trigger: 'blur' }]
      },

      contactList: [
        {
          backgroundColor: '',
          code: 'PHONE',
          icon: '',
          iconCls: '',
          iconClsImage: null,
          id: 'af92fb35649541a19d2db31002ca1e4f',
          text: '手机',
          textColor: ''
        },
        {
          backgroundColor: '',
          code: 'MAIL',
          icon: '',
          iconCls: '',
          iconClsImage: null,
          id: 'ad2f4b1a4817400cb0f903194c780b87',
          text: '邮箱',
          textColor: ''
        }
      ],
      typeList: [
        { text: '产品', id: 'c04434b87f95413e996f128d9d6427ad', code: 'CP' },
        { text: '商务', id: 'ef13158b7ee74f4b9579638dc0ad7f5f', code: 'SW' },
        { text: '服务', id: '1d5e56fb54064aa8bc9f8f30918d001a', code: 'FW' },
        { text: '技术', id: '29ac296e74aa462bb8f954d237297907', code: 'JS' },
        { text: '建议', id: '57c20ec72dad4a998905fb69e411b730', code: 'JY' },
        { text: '其他', id: 'f2072ee01614472ead761ab4e0919ac3', code: 'QT' }
      ],
      resultsList: [
        {
          title: '列表中根据条件过滤报表数据',
          text: '用思维导图进行头脑风暴或信息梳理，激发灵感，高效梳理思路。支持将导图笔记一键切换为大纲笔记，还能在导图中与笔记巧妙联动，让信息串链起来，或是一键为笔记本生成思维导图目录，高效连结相关信息资料。',
          from: '社区论坛 jestq.com/portal.php',
          img: '../../../../assets/images/result.png'
        },
        {
          title: '列表中根据条件过滤报表数据',
          text: '用思维导图进行头脑风暴或信息梳理，激发灵感，高效梳理思路。支持将导图笔记一键切换为大纲笔记，还能在导图中与笔记巧妙联动，让信息串链起来，或是一键为笔记本生成思维导图目录，高效连结相关信息资料。',
          from: '社区论坛 jestq.com/portal.php',
          img: ''
        }
      ],
      name: '',
      hContact: 'PHONE',
      contact: '',
      ruleForm: {
        name: '',
        contact: '',
        type: '产品',
        desc: ''
      },
      totalCount: '', // 搜索结果条数
      searchResult: [], // 搜索结果
      currentPage: 0,
      pageSize: 8,
      pageIndex: 0,
      record: 0
    }
  },
  components: { Header },
  created() {
    this.searchHome()
    this.getLxfs()
    this.getWtfl()
  },
  mounted() {
    if (this.$route.query.showTab) {
      this.showTab = this.$route.query.showTab
      this.keyword = this.$route.query.keyword
      this.pageSize = this.$route.query.pageSize
      this.pageIndex = this.$route.query.pageIndex
      this.record = this.$route.query.record

      this.searchQuery()
    }
  },
  methods: {
    validatorContact(rule, value, callback) {
      if (this.hContact == 'PHONE') {
        if (value === '') {
          callback(new Error('手机号不能为空'))
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          callback()
        }
      } else if (this.hContact == 'MAIL') {
        const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (!value) {
          return callback(new Error('邮箱不能为空'))
        }
        setTimeout(() => {
          if (mailReg.test(value)) {
            callback()
          } else {
            callback(new Error('请输入正确的邮箱格式'))
          }
        }, 100)
      }
    },
    hSearch() {
      this.currentPage = 0
      this.pageIndex = 0
      this.record = '1'
      this.showTab = 'results'
      this.loading = true
      this.searchQuery()
    },
    askQuestions() {
      window.scrollTo(0,document.body.scrollHeight);
      this.ruleForm.desc = this.keyword;
      this.$refs.inputRef.focus()
    },
    hStudy(url) {
      // if (url.text == '视频教程') {
      //   this.$router.push({
      //     path: '/videoCourse'
      //   })
      // } else {
      window.open(url.linkurl)
      // }
    },
    changeContact(item) {
      this.hContact = item.code
      this.$refs.mycontact.resetField()
    },

    searchQuery() {
      const that = this
      let param = {
        keyword: this.keyword || this.placeholder,
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        record: this.record
      }
      searchQuery(param).then((res) => {
        this.searchResult = res.rows

        // this.searchResult.forEach((item) => {
        //   if (!!item.picture && item.picture != 'null') {
        //     item.picture =
        //       'http://doc.jepaas.com/uploads/je_doc_jepaas/images/m_b9d9fa7899f6ee591ccfed43d743de67_r.png'
        //   }
        // })
        this.totalCount = res.totalCount
        if (this.totalCount == 0) {
          this.keyword_ = this.keyword || this.placeholder
        }
        this.loading = false
      })
    },
    searchHome() {
      searchHome().then((res) => {
        if (res.code == 1000) {
          this.linkList = res.obj.sslist

          this.hotList = res.obj.rmList
          this.placeholder = res.obj.rmList[0].SSGLRMTJ_BT
        }
      })
    },
    async saveQus(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!')
        } else {
          // console.log('error submit!!')
          return false
        }
      })
      if (this.ruleForm.name == '' || this.hContact == '') {
        return false
      }
      let param = {
        WTFK_TCR: this.ruleForm.name,
        WTFK_TCRLXFS_CODE: this.hContact,
        WTFK_TCRLXFS_NAME: this.contactList.filter((item) => {
          return item.code == this.hContact
        })[0].text,
        WTFK_LXZH: this.ruleForm.contact,
        WTFK_WTFL_NAME: this.ruleForm.type,
        WTFK_WTFL_CODE: this.typeList.filter((item) => {
          return item.text == this.ruleForm.type
        })[0].code,
        WTFK_LL: this.ruleForm.desc
      }
      await saveQus(param).then((res) => {
        if (res.code == 1000) {
          this.$message({
            message: res.message,
            type: 'success',
            offset: 80
          })
          this.ruleForm = {
            name: '',
            contact: '',
            type: '产品',
            desc: ''
          }

        } else {
          this.$message({
            message: res.message,
            type: 'warning',
            offset: 80
          })
        }
      })

    },
    addOnce(id) {
      let param = {
        id
      }
      addOnce(param).then((res) => {
        console.log(res)
      })
    },
    getLxfs() {
      let param = {
        code: JSON.stringify(['JE_CRM_TCRLXFS'])
      }
      getDic(param).then((res) => {
        if (res.code == '1000' && !!res.obj.length) {
          this.typeList = res.obj[0]
        }
      })
    },
    getWtfl() {
      let param = {
        code: JSON.stringify(['JE_CRM_WTFL'])
      }
      getDic(param).then((res) => {
        if (res.code == '1000' && !!res.obj.length) {
          this.typeList = res.obj[0]
        }
      })
    },
    jump(url) {
      if (url == 'videoCourse') {
        this.$router.push({
          path: '/videoCourse'
        })
      }
      if (url) window.open(url)
    },
    tjump(item) {
      this.addOnce(item.id)
      if (item.uri == 'videoCourse') {
        // this.$router.push({
        //   name: 'playerPage',
        //   query: {
        //     // id: 'qD5J2JQ5KomdO22T5zP'
        //     id: item.jumpPath
        //   }
        // })
        window.open('http://www.jepaas.com/player?id=' + item.jumpPath)
        // window.open('http://www.jepaas.com/player?id=qD5J2JQ5KomdO22T5zP')
        return
      }
      if (item.jumpPath) window.open(item.jumpPath)
    },
    async handleCurrentChange(val) {
      this.pageIndex = val
      this.record = '0'
      await this.searchQuery()
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
}
</script>
<style lang="less" scoped>
.head_box {
  width: 100%;
  height: 15rem;
  overflow: hidden;
  // background-color: #e5714e;
  background-image: url("../../../../assets/imgs/school/schoolhome.png");
  background-size: cover;
  .search_content {
    width: 40.25rem;
    margin: 6rem auto 0;
    .search_box {
      width: 100%;
      .el_ipt {
        width: 80%;
        height: 2.8rem;
        vertical-align: top;
        /deep/.el-input__inner {
          height: 2.8rem;
          border: 0 solid #eb4e4b;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 0.7rem;
          padding-left: 2.2rem;
        }
        /deep/.el-input__icon {
          margin-left: 0.6rem;
          line-height: 2.8;
          font-size: 1rem;
          color: #7b7b7a;
          font-weight: 600;
        }
      }
      .el_btn {
        width: 20%;
        height: 2.8rem;
        // border-top: 1px;
        // border-bottom: 1px;
        margin-left: -0.1rem;
        vertical-align: middle;
        color: #fff;
        background-color: #ff343f;
        font-size: 1.1rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    .search_link {
      width: 100%;
      height: 1rem;
      margin: 8px 0 0 3px;
      font-size: 0.7rem;
      color: #fff;
      .search_link_txt {
        cursor: pointer;
      }
      .search_link_line {
        margin: 0 0.4rem;
      }
    }
  }
  .tabs {
    width: 20rem;
    height: 2.5rem;
    margin: 2.4rem auto 0;
    background-color: #fff;
    border: 1px solid #fff;
    border-top-right-radius: 0.3rem;
    border-top-left-radius: 0.3rem;
    .tabs_item {
      display: inline-block;
      height: 100%;
      width: 50%;
      line-height: 2.5;
      text-align: center;
      cursor: pointer;
      &:first-child {
        border-top-left-radius: 0.3rem;
      }
      &:last-child {
        border-top-right-radius: 0.3rem;
      }
    }
    .tabs_item_active {
      background-color: #e6674c;
      color: #fff;
    }
  }
}
.content_box {
  width: 1200px;
  margin: 0 auto;
  .box_title {
    margin: 3rem 0 1.5rem;
    color: #333;
    font-size: 1rem;
    font-weight: 600;
  }
  .study {
    width: 100%;
    .study_content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .study_item {
        width: 23.3rem;
        height: 13.7rem;
        margin-bottom: 37px;
        line-height: 11rem;
        border-radius: 1rem;
        font-size: 1.8rem;
        text-align: center;
        // background: url('../../../../assets/imgs/school/api.jpg');
        background-size: 100%;
        // background-color: #fa6445;
        color: #fff;
        cursor: pointer;

        &:hover {
          // background: rgba(255, 48, 65, 0.6);
          box-shadow: 2px 2px 10px 0px rgba(255, 48, 65, 0.4);
        }
      }
    }
  }
  .about {
    width: 100%;
    .about_content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      color: #4080ff;
      font-size: 0.8rem;
      .about_item {
        width: 25%;
        margin-bottom: 0.5rem;
        cursor: pointer;
      }
    }
  }
  .results {
    .tip {
      margin: 1.5rem 0;
      font-size: 0.9rem;
      .askQuestions {
        margin: 0 0 0 20px;
        cursor: pointer;
        color: #EB751E;
      }
    }
    .res_item {
      font-size: 0.8rem;
      .res_item_title {
        margin-bottom: 1rem;
        color: #3468cd;
        text-decoration: underline;
        font-size: 0.9rem;
        cursor: pointer;
      }
      .res_item_content {
        display: flex;
        width: 100%;
        .haspic {
          width: 100%;
          // .pic {
          //   float: left;
          //   width: 9rem;
          //   height: 8rem;
          //   margin-right: 1rem;
          //   background-position: center center;
          //   background-size: auto 100%;
          //   background-repeat: no-repeat;
          // }
          .pic {
            float: left;
            width: 8rem;
            min-height: 4.2rem;
            max-height: 8rem;
            margin-right: 1rem;
            img {
              width: 100%;
              max-height: inherit;
            }
          }
          .haspic_content {
            float: left;
            position: relative;
            width: 80%;
            height: 100%;
            .haspic_txt {
              text-align: justify;
            }
            .haspic_from {
              position: absolute;
              bottom: 0;
              color: #aaa;
              font-size: 0.7rem;
            }
          }
        }
        .nopic {
          .nopic_txt {
            text-align: justify;
          }
          .nopic_from {
            margin-top: 10px;
            color: #aaa;
            font-size: 0.7rem;
          }
        }
        .txt_overflow {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }

    // .results_item {
    //   // margin-bottom: 1rem;
    //   font-size: 0.8rem;
    //   .results_item_title {
    //     margin-bottom: 1rem;
    //     color: #3468cd;
    //     text-decoration: underline;
    //     font-size: 0.9rem;
    //     cursor: pointer;
    //   }
    //   .results_item_ {
    //     display: flex;
    //     width: 100%;
    //     // height: 8rem;
    //     // height: 7rem;
    //     p {
    //       margin: 0;
    //     }
    //     .img {
    //       float: left;
    //       margin-right: 1rem;
    //       width: 8rem;
    //       height: 8rem;
    //       img {
    //         // width: 100%;
    //         // height: 100%;
    //       }
    //     }
    //   }
    //   .txt {
    //     text-align: justify;
    //     float: left;
    //     width: 80%;
    //     height: 100%;
    //     position: relative;
    //     .results_item_content {
    //       width: 100%;
    //       // height: 56%;
    //       overflow: hidden;
    //       text-overflow: ellipsis;
    //       display: -webkit-box;
    //       -webkit-line-clamp: 4;
    //       -webkit-box-orient: vertical;

    //       // text-indent: 2rem;
    //     }
    //     .results_item_from {
    //       position: absolute;
    //       // bottom: 0;
    //       color: #aaa;
    //       font-size: 0.7rem;
    //       // padding-top: 2rem;
    //     }
    //   }
    // }
    .pagination {
      margin: 1.5rem 0;
      text-align: center;
    }
  }
  .noresults {
    margin: 1.5rem 0;
    font-size: 0.8rem;
    .askQuestions {
      margin: 0 0 0 20px;
      cursor: pointer;
      color: #EB751E;
    }
  }
  .keyword {
    color: red;
  }
  .hot {
    width: 100%;
    .hot_content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 0.8rem;
      .hot_item {
        width: 47%;
        margin-bottom: 0.5rem;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .dot {
          font-weight: bold;
          margin-right: 0.4rem;
        }
        .txt:hover {
          color: #3468cd;
        }
      }
    }
  }
  .question {
    padding-bottom: 2rem;
    .question_content {
      margin-left: 0.5rem;
      margin-right: 3rem;
      margin-bottom: 6rem;
      /deep/.el-form-item__label {
        font-size: 0.7rem;
        padding: 0 0.8rem 0 0;
      }
      .contact {
        /deep/ .el-form-item__content {
          margin-left: 0 !important;
        }
      }
      /deep/.el-radio__input.is-checked .el-radio__inner {
        border-color: #ff523f;
        background: #ff523f;
      }
      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #ff523f;
      }
      // /deep/.el-button:focus,
      // .el-button:hover {
      //   color: #ff523f;
      //   border-color: #ff523f;
      // }
      .contact_btn_group {
        margin-left: 0.8rem;
      }
      .contact_btn {
        width: 3rem;
      }
      .btn_active {
        background-color: #f75c41;
        color: #fff;
      }
      .subbtn {
        float: right;
        width: 5rem;
        background-color: #f75c41;
      }
    }
  }
}
</style>
<style >
.el-button:active {
  border-color: #ff523f !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ff523f !important;
  color: #fff;
}

.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #606166;
}
.el-input__suffix .el-input__suffix-inner {
  border-color: none;
}
.el-icon-circle-close:before {
  /* content: '\e79d' !important; */
  font-size: 18px;
}
</style>
